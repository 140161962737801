// import 'babel-polyfill';
import Head from "next/head";
import dynamic from "next/dynamic";
import Header from "@/Header";
import Maintain from "@/Maintain";
import { Spin } from "antd";
import "../../config/Global";
import "../../config/Globalfun";
import { getMemberInfo } from "$DATA/userinfo";
import React, { useState, useEffect } from "react";
import SelfExclusionModal from "../SelfExclusionModal";
import Router from "next/router";
import { get } from "$ACTIONS/TlcRequest";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { AvifCheckerModule, isWebPSupported } from "$ACTIONS/helper";

const Footer = dynamic(import("@/Footer"), {ssr: false})

const DynamicRestrictAccess = dynamic(import("@/RestrictAccess/ipError"), {
    loading: () => (
        <Spin
            style={{ position: "absolute", top: "30%", left: 0, right: 0 }}
            spinning={true}
            size="large"
            tip="加载中，请稍后..."
        />
    ),
    ssr: true,
});

function MainComponent(props) {
    const commonParams = {
        headerHeightLock: props.headerHeightLock,
        setLockHeader: props.setLockHeader,
        setLoginStatus: props.setLoginStatus,
        setCircleHasUnRead: props.setCircleHasUnRead,
        setUsercnnterCircle: props.setUsercnnterCircle,
        setUserCenterMoney: props.setUserCenterMoney,
        setUserCenterMemberInfo: props.setUserCenterMemberInfo,
        setShopURL: props.setShopURL,
        setCasinoGameList: props.setCasinoGameList,
        setSlotGameList: props.setSlotGameList,
        setP2PGameList: props.setP2PGameList,
        getPromotionList: props.getPromotionList,
        definedHeaderNode: props.definedHeaderNode,
    };
   //global.HttpStatus = 4;
    const globalStatusKey = global.HttpStatus || props.status || 1;

    const makeFooterDataValue = (isAvif, isWebp, o={}) => [{isAvif, isWebp}, o];
    const [footerData, setFooterData] = useState(makeFooterDataValue(false, false, {}));
    useEffect(() => {
        AvifCheckerModule.isAvifSupportedPromise().then(b => {
            get(ApiPort.GETFooter).then(res => {
                setFooterData(makeFooterDataValue(b, isWebPSupported(), res?.data || {}))
            })
        });
    }, [])

    switch (globalStatusKey) {
        case 5:
            return <React.Fragment>{props.children}</React.Fragment>;
        case 1:
            return (
                <div
                    className={`tlc-container-wrapper ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    } ${
                        props.headerHeightLock
                            ? "_" + props.headerHeightLock
                            : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} />
                    {props.children}
                    <Footer isIndexPage={props.isIndexPage} key="main-footer" footerData={footerData} />
                </div>
            );
        case 2:
            return (
                <div
                    className={`tlc-container-wrapper ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    } ${
                        props.headerHeightLock
                            ? "_" + props.headerHeightLock
                            : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} />
                    {props.children}
                    <Footer key="main-footer" footerData={footerData} />
                </div>
            );
        case 3:
            return <Maintain />;
        case 4:
            return <DynamicRestrictAccess httpStatus={global.HttpStatus} />;
        case 6:
            return (
                <div
                    className={`tlc-container-wrapper-Diamond ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    } ${
                        props.headerHeightLock
                            ? "_" + props.headerHeightLock
                            : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} />
                    {props.children}
                    <Footer key="main-footer" footerData={footerData} />
                </div>
            );
        case 7:
            return <DynamicRestrictAccess />;
        case 8:
            return <div className={`${props.wrapperClassName}`}>
                <div style={{ display: 'none', opacity: 0, width: 0, height: 0 }}>
                    <Header key="main-header" {...commonParams} />
                </div>
                {props.children}
                <Footer key="main-footer" footerData={footerData} />
            </div>
        default:
            return <div>500 Error!</div>;
    }
}

export default ({
    isIndexPage,
    status,
    // 默认需要的内容
    children,
    // 锁定Header状态栏大小
    setLockHeader,
    // 设置登陆状态
    setLoginStatus,
    // 锁定Header状态栏大小所需要的配置参数
    headerHeightLock,
    // 设置Header部分未读标识（小红点）
    setCircleHasUnRead,
    // 设置UserCenter部分未读标识（小红点）
    setUsercnnterCircle,
    // 设置UserCenter的钱
    setUserCenterMoney,
    // 设置UserCenter的会员信息
    setUserCenterMemberInfo,
    // 设置首页的8号商城链接
    setShopURL,
    // 设置真人的游戏数据
    setCasinoGameList,
    // 设置老虎机的游戏数据
    setSlotGameList,
    // 设置棋牌的游戏数据
    setP2PGameList,
    // 顶层样式表名称
    wrapperClassName,
    // 自定义Header
    definedHeaderNode,
    title = "亚洲首选体育平台，英超热刺纽卡赞助伙伴 ",
    description = "拥有13年历史实力品牌，NBA球星联合推荐、英超俱乐部合作伙伴，提供最全五大足球联赛等体育赛事，以及多样化游戏娱乐项目，迎战欧洲杯最佳首选APP。",
    Keywords = "英超俱乐部赞助商/NBA球星代言娱乐平台/托特纳姆热刺赞助伙伴/纽卡斯尔赞助伙伴/伯恩利官方球衣赞助商/托尼帕克品牌大使/卡西利亚斯品牌大使/科比布莱恩品牌大使/史蒂夫纳什品牌大使/罗比福勒桑德兰品牌大使/刀塔OG战队赞助商",
    getPromotionList,
}) => {
    useEffect(() => {
        const HTML_ID = 'seonScriptTag';
        if (!document.getElementById(HTML_ID)) {
            const scriptLink = document.createElement("script");
            scriptLink.id = HTML_ID;
            scriptLink.src = "https://cdn.keepitsimple88.com/js/v6/agent.umd.js";
            scriptLink.type = "text/javascript";
            scriptLink.onload = () => {
                if (window?.seon && typeof window.seon.init === 'function') {
                    window.seon.init();
                    global.SEONInitialized = true;
                }
            };
            document.querySelectorAll("head")[0].appendChild(scriptLink);
        }
    },[]);
    return [<Head key="layout-head">
        
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        {/* <meta key="viewport" name="viewport" content="width=device-width, initial-scale=0, user-scalable=yes, maximum-scale=1.0" /> */}
        <meta name="description" content={description} />
        <meta name="Keywords" content={Keywords} />
        <link
            rel="shortcut icon"
            type="image/x-icon"
            href="/cn/img/favicon.ico"
        />
        {/* <script src="https://www.googletagmanager.com/gtag/js?id=UA-45814256-7"></script> */}
        {/* <script src="/cn/Geetest/gee-test.js?v=2" /> */}
    </Head>,
    <>
        <MainComponent
            key="main-component"
            isIndexPage={isIndexPage}
            status={status}
            setLockHeader={setLockHeader}
            setLoginStatus={setLoginStatus}
            wrapperClassName={wrapperClassName}
            headerHeightLock={headerHeightLock}
            setCircleHasUnRead={setCircleHasUnRead}
            setUsercnnterCircle={setUsercnnterCircle}
            setUserCenterMoney={setUserCenterMoney}
            setUserCenterMemberInfo={setUserCenterMemberInfo}
            setShopURL={setShopURL}
            setCasinoGameList={setCasinoGameList}
            setSlotGameList={setSlotGameList}
            setP2PGameList={setP2PGameList}
            children={children}
            getPromotionList={getPromotionList}
            definedHeaderNode={definedHeaderNode}
        />
        <SelfExclusionModal
            ModalType={1}
            OpenModalUrl="Home"
            afterCloseModal={() => {
                Router.push("/cn");
            }}
        />
    </>]
};
